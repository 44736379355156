import React from 'react'
import StaticFilesUrls from '../assets/images'

const Spinner = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      <img src={StaticFilesUrls.spinner} alt='Downloading PDFs...' className='pdf-spinner' />
    </div>
  )
}

export default Spinner