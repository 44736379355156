import { createContext, useReducer } from 'react'
import dogReducer from './dogReducer'
import axios from 'axios'
import tinycolor from 'tinycolor2'

const DogContext = createContext()

export const DogProvider = ({ children }) => {
  const dates = new FormData()

  dates.append('start_date', new Date(Date.now() - 21 * 24 * 60 * 60 * 1000).toISOString().substring(0, 10))
  dates.append('end_date', new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString().substring(0, 10))

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }

  const initialState = {
    dogs: [],
    filteredDogs: [],
    allChecked: false,
    loading: true,
    error: '',
    singleDogError: '',
    dog: {},
    storeChanged: 0,
    errorRes: '',
    pdfSavingVertical: false,
    pdfSavingHorizontal: false
  }

  const [state, dispatch] = useReducer(dogReducer, initialState)

  const dogsFromApi = async (storeNumberDB) => {
    try {
      dispatch({ type: 'RESET_RESERVATIONS_ERROR' })
      const resStore = await axios.get(`/stores/${storeNumberDB}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      }).catch(e => console.log({e}))
      const storeUrl = resStore.data.data.url
      const key = resStore.data.data.key
      const { data } = await axios.post(`${storeUrl}api/v1/reservations?key=${key}`, dates, config).catch((e) => console.log({e}))

      const entries = Object.entries(data.data)
      const reservations = entries.map(entry => entry[1])

      const dogsArray = []
      reservations.forEach(reservation => {
        if (reservation.reservation_type.type.includes('Sleepover') || reservation.reservation_type.type.includes('Day Care')) {
          const dog = {
            id: reservation.animal.id,
            name: reservation.animal.name,
            breed: reservation.animal.breed,
            ownerName: `${reservation.owner.first_name} ${reservation.owner.last_name}`,
            ownerId: reservation.owner.id,
            birthday: null,
            image: null,
            icons: null,
            gender: null
          }
          dogsArray.push(dog)
        }
      })

      if (dogsArray.length === 0) {
        return dispatch({ type: 'NO_RESERVATIONS_FOUND' })
      }



      dogsArray.forEach((dog, index) => {
        for (let i = index + 1; i < dogsArray.length; i++) {
          if (dog.id === dogsArray[i].id) {
            dogsArray.splice(i, 1)
          }
        }
      })

      dogsArray.forEach((dog, index) => {
        for (let i = index + 1; i < dogsArray.length; i++) {
          if (dog.id === dogsArray[i].id) {
            dogsArray.splice(i, 1)
          }
        }
      })

      dogsArray.forEach((dog, index) => {
        for (let i = index + 1; i < dogsArray.length; i++) {
          if (dog.id === dogsArray[i].id) {
            dogsArray.splice(i, 1)
          }
        }
      })


      const getDogsByName = () => {
        let i = 0;
        const dogsArrNested = []
        dogsArray.forEach(async dog => {
          const res = await axios.get(`${storeUrl}api/v1/custom_field_search?key=${key}&form_id=2&field_name=first_name&search=${dog.name}`).catch((e) => console.log({e}))
          const result = res.data.data
          result.forEach(res => {
            if (res.system_id === dog.id) {
              const dogBirthday = new Date(res.birthday * 1000).toLocaleDateString('en-US')
              dog.birthday = dogBirthday
              dog.icons = res.custom_animal_icons
              dog.gender = res.gender
              dog.image = res.image
              dispatch({ type: 'GET_SINGLE_DOG', payload: { ...dog, isChecked: false } })
            }
          })
          dogsArrNested.push(result)
        })

      }

      const dogsArrNested = getDogsByName()

    } catch (err) {
      dispatch({ type: 'SET_LOADING_ERROR', payload: 'Something went wrong when fetching the data, please try reloading the page' })
    }


  }

  const filterDogs = async (dogs, name, owner, breed) => {
    dispatch({ type: 'CLEAR_SINGLE_DOG' })
    let dogsFiltered = [...dogs]
    if (name.trim().length > 0) {
      dogsFiltered = dogsFiltered.filter(dog => dog.name.startsWith(name) || dog.name.toLowerCase().startsWith(name))
    }
    if (owner.trim().length > 0) {
      dogsFiltered = dogsFiltered.filter(dog => dog.ownerName.startsWith(owner) || dog.ownerName.toLowerCase().startsWith(owner) || dog.ownerName.includes(owner) || dog.ownerName.toLowerCase().includes(owner))
    }
    if (breed.trim().length > 0) {
      dogsFiltered = dogsFiltered.filter(dog => dog.breed.startsWith(breed) || dog.breed.toLowerCase().startsWith(breed))
    }

    dispatch({ type: 'FILTER_DOGS', payload: [...new Map(dogsFiltered.map(item => [item["id"], item])).values()] })
  }

  const clearDogs = () => {
    dispatch({ type: 'CLEAR_DOGS' })
  }

  const changeAllChecked = val => {
    dispatch({ type: 'CHANGE_ALL_CHECKED', payload: val })
  }

  const checkAllDogs = () => {
    dispatch({ type: 'CHECK_ALL_DOGS' })
  }

  const changeSingleChecked = (id, val) => {
    dispatch({ type: 'CHECK_SINGLE_DOG', payload: { id, val } })
  }

  const clearSingleDog = () => {
    dispatch({ type: 'CLEAR_SINGLE_DOG' })
  }

  const storeChange = () => {
    dispatch({ type: 'STORE_CHANGE' })
  }

  const hexToRGB = (val) => {
    let color = tinycolor(val)
    color.setAlpha(0.3)
    color = color.toRgbString()
    return color
  }

  const pdfSavedPendingVertical = () => {
    dispatch({ type: 'PDF_SAVED_PENDING_VERTICAL' })
  }

  const pdfSavedFinishedVertical = () => {
    dispatch({ type: 'PDF_SAVED_FINISHED_VERTICAL' })
  }

  const pdfSavedPendingHorizontal = () => {
    dispatch({ type: 'PDF_SAVED_PENDING_HORIZONTAL' })
  }

  const pdfSavedFinishedHorizontal = () => {
    dispatch({ type: 'PDF_SAVED_FINISHED_HORIZONTAL' })
  }

  return <DogContext.Provider value={{
    dogs: state.dogs,
    filteredDogs: state.filteredDogs,
    allChecked: state.allChecked,
    loading: state.loading,
    error: state.error,
    dog: state.dog,
    storeChanged: state.storeChanged,
    errorRes: state.errorRes,
    pdfSavingVertical: state.pdfSavingVertical,
    pdfSavingHorizontal: state.pdfSavingHorizontal,
    dogsFromApi,
    clearDogs,
    filterDogs,
    changeAllChecked,
    checkAllDogs,
    changeSingleChecked,
    clearSingleDog,
    storeChange,
    hexToRGB,
    pdfSavedPendingVertical,
    pdfSavedFinishedVertical,
    pdfSavedPendingHorizontal,
    pdfSavedFinishedHorizontal,
  }}>
    {children}
  </DogContext.Provider>
}

export default DogContext