import { createContext, useReducer } from 'react'
import userReducer from './userReducer'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'


const UserContext = createContext()

export const UserProvider = ({ children }) => {

  const navigate = useNavigate()

  const initialState = {
    user: {},
    loading: true,
    error: '',
    emailSuccess: '',
    emailError: '',
  }

  const [state, dispatch] = useReducer(userReducer, initialState)

  const login = async (email, password) => {
    try {
      const resUser = await axios.post('/user/login', {email, password})
      const user = { email: resUser.data.data._doc.email, storeNumber: resUser.data.data._doc.store }
      localStorage.removeItem('token')
      localStorage.setItem('token', resUser.data.data.token)
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      };
      const resStores = await axios.create({
        withCredentials: true
      }).get('/stores', config)
      const stores = resStores.data.data
      
      stores.forEach(store => {
        if(store.number === user.storeNumber) {
          user.storeName = store.name
          user.storeUrl = store.url
        }
      })
    
      localStorage.setItem('cbpc_user', JSON.stringify(user))

      dispatch({ type: 'LOGIN_SUCCESS', payload: user })

      navigate('/')
    } catch (err) {
      dispatch({ type: 'LOGIN_ERROR', payload: err.response.data.message })
    }
  }

  const logout = () => {
    dispatch({ type: 'LOGOUT' })
    localStorage.removeItem('cbpc_user')
    localStorage.removeItem('token')
    navigate('/login')
  }

  const resetPassword = async (email) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }
    const body = JSON.stringify({ email })

    try {
      await axios.post('/user/password/reset', body, config)
      dispatch({ type: 'SEND_EMAIL_SUCCESS', payload: 'Check your email to get your new password.' })
      setTimeout(() => {
        dispatch({ type: 'SEND_EMAIL_REMOVE_MESSAGE' })
      }, 5000)
    } catch (err) {
      dispatch({ type: 'SEND_EMAIL_FAIL', payload: 'There was a problem with sending the email, please try again.' })
    }
  }

  return <UserContext.Provider value={{
    user: state.user,
    loading: state.loading,
    error: state.error,
    emailSuccess: state.emailSuccess,
    emailError: state.emailError,
    login,
    logout,
    resetPassword
  }}>
    {children}
  </UserContext.Provider>
}

export default UserContext