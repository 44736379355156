const permittedIconClasses = [
    'fa fa-flag',
    'far fa-flag',
    'fa fa-suitcase-rolling',
    'far fa-eye-slash',
    'fa fa-deaf',
    'fa fa-volume-up',
    'fa fa-level-up-alt',
    'fa fa-sign-out-alt',
    'fa fa-sign-in-alt',
    'far fa-angry',
    'far fa-hand-paper',
    'fa fa-dot-circle',
    'far fa-times-circle',
    'fab fa-kickstarter',
    'fab fa-kickstarter-k',
    'fa fa-cookie-bite',
    'fa fa-notes-medical',
    'fa fa-pills',
    'fa fa-poop',
    'fa fa-sun',
    'fa fa-temperature-high',
    'fas fa-wave-square',
    'fa fa-leaf',
    'fa fa-bug',
    'fa fa-water',
    'fa fa-bolt',
    'fa fa-retweet',
    'fa fa-dog',
    'fa fa-bomb fa-3x',
    'fa fa-bomb',
    'fa fa-heart',
    'far fa-bell',
    'fa fa-band-aid',
    'fas fa-heart',
    'fa fa-toilet-paper',
    'fa fa-refresh',
    'fa fa-bars',
    'fa fa-tint',
    'fa fa-wind',
    'fa fa-redo-alt',
    'fa fa-door-open'
]

export default permittedIconClasses