

const dogReducer = (state = { dogs: [], filteredDogs: [], dog: {}, loading: true, allChecked: false, storeChanged: 0, pdfSavingVertical: false, pdfSavingHorizontal: false, errorRes: '' }, action) => {
  switch (action.type) {
    case 'RESET_RESERVATIONS_ERROR': {
      return {
        ...state,
        errorRes: ''
      }
    }
    case 'NO_RESERVATIONS_FOUND':
      {
        return {
          ...state,
          loading: false,
          errorRes: 'No data found'
        }
      }
    case 'STORE_CHANGE': {
      return {
        ...state,
        storeChanged: state.storeChanged + 1
      }
    }
    case 'PDF_SAVED_PENDING_VERTICAL':
      return {
        ...state,
        pdfSavingVertical: true
      }
    case 'PDF_SAVED_FINISHED_VERTICAL':
      return {
        ...state,
        pdfSavingVertical: false
      }
    case 'PDF_SAVED_PENDING_HORIZONTAL':
      return {
        ...state,
        pdfSavingHorizontal: true
      }
    case 'PDF_SAVED_FINISHED_HORIZONTAL':
      return {
        ...state,
        pdfSavingHorizontal: false
      }
    case 'GET_SINGLE_DOG':
      return {
        ...state,
        // dog: action.payload,
        dog: action.payload,
        // dogs: [...state.dogs, action.payload],
        dogs: (() => {
          let i = 0 
          state.dogs.forEach(el => {
            if(Number(el.id) === Number(action.payload.id)) i++
          })
          if(i === 0) {
            return [...state.dogs, action.payload]
          } else {
            return [...state.dogs]
          }
         })(),
        loading: false
      }
    case 'GET_DOGS':
      return {
        ...state,
        loading: false,
        dogs: action.payload
      }
    case 'FILTER_DOGS':
      return {
        ...state,
        filteredDogs: action.payload,
      }
    case 'CLEAR_DOGS':
      return {
        ...state,
        loading: true,
        dogs: [],
        filteredDogs: []
      }
    case 'CLEAR_SINGLE_DOG':
      return {
        ...state,
        dog: {}
      }
    case 'CHANGE_ALL_CHECKED':
      return {
        ...state,
        allChecked: action.payload
      }
    case 'CHECK_ALL_DOGS':
      return {
        ...state,
        filteredDogs: state.filteredDogs.length > 0 ? state.filteredDogs.map(dog => {
          dog.isChecked = state.allChecked
          return dog
        }) :
          state.dogs.map(dog => {
            dog.isChecked = state.allChecked
            return dog
          })
      }
    case 'CHECK_SINGLE_DOG':
      return {
        ...state,
        dogs: state.dogs.map(dog => {
          if (dog.id === action.payload.id) {
            dog.isChecked = action.payload.val
            return dog
          } else {
            return dog
          }
        })
      }
    case 'SET_LOADING_ERROR':
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case 'SINGLE_DOG_ERROR':
      return {
        ...state,
        singleDogError: action.payload
      }
    default:
      return state
  }
}

export default dogReducer