import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import StaticFilesUrls from "../assets/images/index";
import { Button, Grid } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid grey",
  boxShadow: 24,
  p: 4,
};

export default function InstructionsModal({ isOpen, close }) {
  return (
    <Modal
      open={isOpen}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h3" component="h2">
          Printing Instructions
        </Typography>
        <Typography variant="h5" id="modal-modal-description" sx={{ my: 2 }}>
          The following instructions are in a Google Chrome browser. <br />{" "}
          After you download the PDF, press Ctrl+P to display the printing
          dialog. <br /> Expand the 'More settings' tab. <br />
          Choose A4 for the paper size.
          <br /> Choose 'Fit to printable area' for scale.
          <br />
        </Typography>
        <img
          src={StaticFilesUrls.printInstructions}
          alt="printing instructions"
          width={600}
          height={400}
        />
      </Box>
    </Modal>
  );
}
