import { useState, useEffect, useContext } from 'react'
import UserContext from '../context/user/userContext'
import DogContext from '../context/dog/dogContext'
import axios from '../utils/axios'

const Header = () => {
  const user = JSON.parse(localStorage.getItem('cbpc_user'))

  const { logout } = useContext(UserContext)
  const { dogsFromApi, clearDogs, clearSingleDog, storeChange } = useContext(DogContext)

  const [store, setStore] = useState(-1)
  const [allStores, setAllStores] = useState([])

  const handleLogout = e => {
    e.preventDefault()
    logout()
    clearDogs()
    clearSingleDog()
  }

  const handleStoreChange = e => {
    storeChange()
    setStore(e.target.value)
    clearSingleDog()
    clearDogs()
    dogsFromApi(e.target.value)
  }



  useEffect(() => {
    if(user?.storeNumber === -1) {
      const getAllStores = async () => {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
        const { data } = await axios.get('/stores', config)
        return data
      }
      getAllStores().then(data => setAllStores(data.data.sort((
        (prev, next) => {
          let prevName = prev.name.toLowerCase();
          let nextName = next.name.toLowerCase();
          if(prevName < nextName) {
            return -1;
          }
          if(prevName < nextName) {
            return 1;
          }
          return 0;
        }
      )))).catch(e => console.log(e.message))
    }
  }, [])


  return (
    <div className='header'>
      <div className="header-left">
        <img src="https://hub.centralbarkusa.com/purple-logo.png" alt="Logo" className='logo' />
        <h1 className='header-title'>Play ID Card Generator</h1>
      </div>
      <div className="header-right">

        {user?.storeNumber !== -1 ? <h5>{user?.storeName}</h5> : (
          <select className='select-store' value={store} onChange={e => handleStoreChange(e)}>
            {
              allStores.map(el => (
                <option key={el.number} value={el.number}>{el.name}</option>
              ))
            }
          </select>
        )}
        <h6>Logged in as {user?.email}</h6>
        <a href="#" className='logout-btn' onClick={e => handleLogout(e)}>Logout</a>
      </div>
    </div>
  )
}

export default Header

