import React from 'react'

const TableHead = () => {
  return (
    <thead className='table-head table-head-sticky'>
      <tr>
        <th className='th-empty'></th>
        <th>Name</th>
        <th>Owner Name</th>
        <th>Breed</th>
        <th>Image</th>
        <th>Birthday</th>
      </tr>
    </thead>
  )
}

export default TableHead