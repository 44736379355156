import './App.css'
import { DogProvider } from './context/dog/dogContext'
import { UserProvider } from './context/user/userContext'
import Login from './components/Login'
import MainPage from './components/MainPage'
import ForgotPassword from './components/ForgotPassword'
import { Routes, Route, useNavigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react'
import RouteGuard from './RouteGuard'

function App() {
  const navigate = useNavigate()

  const user = localStorage.getItem('cbpc_user') ? localStorage.getItem('cbpc_user') : ''

  useEffect(() => {
    if(!user) {
      navigate('/login')    
    }
  }, [])

  return (
    <>
      <UserProvider>
        <DogProvider>
          <div className="container">
            <Routes>
              <Route exact path='/' element={<RouteGuard />} >
                <Route path="/" element={<MainPage />} />
              </Route>
              <Route path='/login' element={<Login />} />
              <Route path='/forgotpassword' element={<ForgotPassword />} />
            </Routes>
          </div>
        </DogProvider>
      </UserProvider>
      <ToastContainer />
    </>
  );
}

export default App;
