import React, { useEffect, useContext, useState } from 'react'
import GeneratePdfButtons from './GeneratePdfButtons'
import Header from './Header'
import TableContent from './TableContent'
import TableForm from './TableForm'
import TableHead from './TableHead'
import { useNavigate } from 'react-router-dom'
import DogContext from '../context/dog/dogContext'
import FilteredContent from './FilteredContent'
import EmptyContent from './EmptyContent'

const MainPage = () => {
  const { singleDogError, clearDogs, dogsFromApi, dogs, filteredDogs } = useContext(DogContext)
  const navigate = useNavigate()
  let user = localStorage.getItem('cbpc_user')
  

  const [filterActive, setFilterActive] = useState(false)
  useEffect(() => {
    clearDogs()
    if (!user) { 
      return navigate('/login') 
    }
    user = JSON.parse(localStorage.getItem('cbpc_user'))
    if (user) {
      dogsFromApi(user.storeNumber)
    }
  }, [user])

  useEffect(() => {
    function handleLogout() {
      console.log("logout!");
      navigate('/login');
    }
    window.addEventListener('logout', handleLogout)
    return () => window.removeEventListener("logout", handleLogout);
  }, [])

  return (
    <>
      <Header />
      {singleDogError && <p className='single-dog-error'>There was an error</p>}
      <div className="tableWrap">
        <table className='table'>
          <TableHead />
          <TableForm setFilterActive={setFilterActive} />
          {
            filteredDogs.length > 0
            ? <FilteredContent /> : filterActive
            ? <EmptyContent /> : dogs.length === 0
            ? <EmptyContent /> : <TableContent />
          }
        </table>
      </div>
      <GeneratePdfButtons />
    </>
  )
}

export default MainPage