import { useState } from "react";
import axios from '../utils/axios'
import { useContext } from "react";
import DogContext from "../context/dog/dogContext";
import { saveAs } from "file-saver";
import Spinner from "./Spinner";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import InstructionsModal from "./InstructionsModal";

const GeneratePdfButtons = () => {
  const [loadingVertical, setLoadingVertical] = useState(false);
  const [loadingHorizontal, setLoadingHorizontal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const {
    dogs,
    hexToRGB,
    pdfSavedPendingVertical,
    pdfSavedFinishedVertical,
    pdfSavedPendingHorizontal,
    pdfSavedFinishedHorizontal,
  } = useContext(DogContext);

  const handleGenerateVertical = async (e) => {
    e.preventDefault();
    setLoadingVertical(true);
    const dogsChecked = dogs.filter((dog) => dog.isChecked === true);
    const dogsCheckedWithClass = [];
    dogsChecked.forEach((dog) => {
      let i = 0;
      dog.image = dog.image?.replaceAll(" ", "%20");
      dog.icons.forEach((icon) => {
        if (icon.class === "fa fa-flag") {
          dog = {
            ...dog,
            class: icon,
          };
          dog.class.color = hexToRGB(dog.class.color);
          dogsCheckedWithClass.push(dog);
          i = 1;
        }
        if (icon.class === "far fa-flag") {
          dog = {
            ...dog,
            class: icon,
          };
          dog.class.color = hexToRGB(dog.class.color);
          dogsCheckedWithClass.push(dog);
          i = 1;
        }
      });
      if (i === 0) {
        dogsCheckedWithClass.push(dog);
      }
    });

    if (dogsCheckedWithClass.length <= 5) {
      for (const [i, dog] of dogsCheckedWithClass.entries()) {
        pdfSavedPendingVertical();
        try {
          const res = await axios
          .post("/pdf/vertical", dog, {
            responseType: "blob",
            headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
						},
          });
          const pdfBlob = new Blob([res.data], { type: "application/pdf" });
          saveAs(pdfBlob, `${dog.id}-${dog.name}-${dog.breed.replace(/ /g, "")}.pdf`);
          if (i === dogsCheckedWithClass.length) {
            pdfSavedFinishedVertical();
          }
        } catch(e){
          console.log("error generating pdf", {err: e})
        }
        
        setLoadingVertical(false);
      }
    } else {
      axios
        .post(
          "/pdf/send/vertical",
          JSON.stringify({
            dogs: dogsCheckedWithClass,
            email: JSON.parse(localStorage.getItem("cbpc_user")).email,
          }),
          {
						headers: {
							'Content-Type': 'application/json',
							Authorization: `Bearer ${localStorage.getItem('token')}`,
						},
					}
        )
        .then(({ data }) => {
          console.log(data);
          toast.success("Your pdfs will be sent to your email in a zip file");
        })
        .catch(() => navigate("/login"))
        .finally(() => {
          setLoadingVertical(false);
        });
    }
  };

  const handleGenerateHorizontal = (e) => {
    e.preventDefault();
    setLoadingHorizontal(true);
    const dogsChecked = dogs.filter((dog) => dog.isChecked === true);
    const dogsCheckedWithClass = [];
    dogsChecked.forEach((dog) => {
      let i = 0;
      dog.image = dog.image?.replaceAll(" ", "%20");
      dog.icons.forEach((icon) => {
        if (icon.class === "fa fa-flag") {
          dog = {
            ...dog,
            class: icon,
          };
          dog.class.color = hexToRGB(dog.class.color);
          dogsCheckedWithClass.push(dog);
          i = 1;
        }
        if (icon.class === "far fa-flag") {
          dog = {
            ...dog,
            class: icon,
          };
          dog.class.color = hexToRGB(dog.class.color);
          dogsCheckedWithClass.push(dog);
          i = 1;
        }
      });
      if (i === 0) {
        dogsCheckedWithClass.push(dog);
      }
    });

    if (dogsCheckedWithClass.length <= 5) {
      let j = 0;
      dogsCheckedWithClass.forEach(async (dog) => {
        pdfSavedPendingHorizontal();
        const token = localStorage.getItem("token");
        console.log(token);
        try {
          const res = await axios
          .post("/pdf/horizontal", dog, {
            responseType: "blob",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .catch(() => navigate("/login"));
          const pdfBlob = new Blob([res.data], { type: "application/pdf" });
          saveAs(pdfBlob, `${dog.id}-${dog.name}-${dog.breed.replace(/ /g, "")}.pdf`);
          j++;
          console.log("DOCUMENT", j);
          if (j === dogsCheckedWithClass.length) {
            pdfSavedFinishedHorizontal();
          }
        } catch(err) {
          console.log("Error saving pdf", {err})
        }
       
        setLoadingHorizontal(false);
      });
    } else {
      axios
        .post(
          "/pdf/send/horizontal",
          JSON.stringify({
            dogs: dogsCheckedWithClass,
            email: JSON.parse(localStorage.getItem("cbpc_user")).email,
          }),
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then(({ data }) => {
          console.log(data);
          toast.success("Your pdfs will be sent to your email in a zip file");
        })
        .catch(() => navigate("/login"))
        .finally(() => {
          setLoadingHorizontal(false);
        });
    }
  };

  return (
    <div className="pdf-buttons">
      <a href="#" style={{ width: "288px" }} onClick={handleGenerateVertical}>
        {loadingVertical ? <Spinner /> : "Generate vertical PDF"}
      </a>
      <a href="#" style={{ width: "315px" }} onClick={handleGenerateHorizontal}>
        {loadingHorizontal ? <Spinner /> : "Generate horizontal PDF"}
      </a>
      <button onClick={()=>setIsOpen(true)}>Printing Instructions</button>
      <InstructionsModal isOpen={isOpen} close={()=>setIsOpen(false)} />
    </div>
  );
};

export default GeneratePdfButtons;
