import { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import UserContext from '../context/user/userContext'
import DogContext from '../context/dog/dogContext'
import { Link } from 'react-router-dom'
import StaticFilesUrls from '../assets/images'

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const { login, error, emailSuccess, emailError } = useContext(UserContext)
  const { clearSingleDog, clearDogs } = useContext(DogContext)

  const navigate = useNavigate()

  useEffect(() => {
    const user = localStorage.getItem('cbpc_user')
    if (user) navigate('/')
  }, [])

  const handleEmailChange = e => setEmail(e.target.value)
  const handlePasswordChange = e => setPassword(e.target.value)

  const handleSubmit = e => {
    e.preventDefault();
    login(email, password)
    clearSingleDog()
    clearDogs()
  }

  return (
    <>
      <div className="login-card">
        {error && <h6 className='login-error'>{error}</h6>}
        {emailSuccess && <h6 className='login-success'>{emailSuccess}</h6>}
        {emailError && <h6 className='login-error'>{emailError}</h6>}
        <img src={StaticFilesUrls.logo} alt="LOGO" className='login-logo' />
        <form className='login-form' onSubmit={e => handleSubmit(e)}>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" value={email} onChange={e => handleEmailChange(e)} required />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input type="password" value={password} onChange={e => handlePasswordChange(e)} required />
          </div>
          <div className="login-btn-container">
            <input type="submit" value='Login' className='login-btn' />
            <Link className='forgot-password-link' to='/forgotpassword'>Forgot Your Password?</Link>
          </div>
        </form>
      </div>
    </>
  )
}

export default Login