import { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import StaticFilesUrls from '../assets/images'
import UserContext from '../context/user/userContext'

const ForgotPassword = () => {
  const [email, setEmail] = useState('')

  const { emailSuccess, emailError, resetPassword } = useContext(UserContext)

  const navigate = useNavigate()

  const handleEmailChange = e => setEmail(e.target.value)

  const handleSubmit = e => {
    e.preventDefault();
    resetPassword(email)
    navigate('/login')
  }


  return (
    <>
      <div className="login-card">
        {emailSuccess && <h6 className='login-success'>{emailSuccess}</h6>}
        {emailError && <h6 className='login-error'>{emailError}</h6>}
        <img src={StaticFilesUrls.logo} alt="LOGO" className='login-logo' />
        <form className='login-form' onSubmit={e => handleSubmit(e)}>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" value={email} onChange={e => handleEmailChange(e)} required />
          </div>
          <div className="login-btn-container">
            <input type="submit" value='Get new password' className='login-btn reset-password-btn' />
          </div>
        </form>
      </div>
    </>
  )
}

export default ForgotPassword