import React from 'react'
import DogContext from '../context/dog/dogContext'
import permittedIconClasses from '../enums/permittedIconClasses'

const TableContent = () => {
  const { loading, changeSingleChecked, dogs } = React.useContext(DogContext)
  const handleCheckChange = e => {
    dogs.forEach(dog => {
      if (dog.id === e.target.id) {
        changeSingleChecked(dog.id, !dog.isChecked)
      }
    })
  }

  return (
    <tbody className='tbody table-wrapper'>
      {loading && <h3 className='loading-placeholder'>Please wait, the data is loading...</h3>}
      {dogs.length && dogs.map((dog, idx) => {
        return (
          <tr key={dog.id}>
            <td>
              <input id={dog.id} className='dog-check-input' type="checkbox" checked={dog.isChecked} value={dog.isChecked} onChange={e => handleCheckChange(e)} name='check' />
            </td>
            <td>
              <div className="name-flags">
                <p style={{ marginBottom: '0px' }}>{dog.name}</p>
                <div className="flags">
                  {!dog.icons ? '' : dog.icons.map((icon, idx) => {
                    if (permittedIconClasses.includes(icon.class)) {
                      return <i key={idx} className={icon.class} style={{ color: `${icon.color}`, margin: '0 2px' }}></i>
                    } else {
                      return ''
                    }
                  }
                  )}
                </div>
              </div>
            </td>
            <td>
              {dog.ownerName}
            </td>
            <td>
              {dog.breed}
            </td>

            <td>
              {dog.image ? <img alt={dog.id} src={dog.image} className='table-entry-image' style={{ borderRadius: '50%', objectFit: 'cover' }} /> : ''}
            </td>


            <td>
              {dog.birthday}
            </td>
          </tr>
        )
      })}
    </tbody>
  )
}

export default TableContent