import { useState, useContext, useEffect } from 'react'
import DogContext from '../context/dog/dogContext'

const TableForm = ({ setFilterActive }) => {
  const [checked, setChecked] = useState(false)
  const [dogName, setDogName] = useState('')
  const [ownerName, setOwnerName] = useState('')
  const [breed, setBreed] = useState('')

  const { dogs, allChecked, filterDogs, changeAllChecked, checkAllDogs, loading, errorRes } = useContext(DogContext)

  useEffect(() => {
    if(dogName.trim() === '' && ownerName.trim() === '' && breed.trim() === '') {
      setFilterActive(false)
    } else {
      setFilterActive(true)
    }
  }, [dogName, ownerName, breed])

  const handleDogNameChange = e => {
    setDogName(e.target.value)
    filterDogs(dogs, e.target.value, ownerName, breed)
  }

  const handleOwnerNameChange = e => {
    setOwnerName(e.target.value)
    filterDogs(dogs, dogName, e.target.value, breed)
  }

  const handleBreedChange = e => {
    setBreed(e.target.value)
    filterDogs(dogs, dogName, ownerName, e.target.value)
  }

  const handleCheckChange = e => {
    changeAllChecked(!checked)
    checkAllDogs()
    setChecked(!checked)
  }

  useEffect(() => {
    document.querySelectorAll('.dog-check-input').forEach(function(input) {
      input.checked = allChecked
    })
  }, [allChecked])


  return (
    <tbody>
      <tr className='table-form-row table-form-sticky'>
        <td>
          <input type="checkbox" value={checked} onChange={e => handleCheckChange(e)} />
        </td>
        <td>
          <input type="text" value={dogName} style={{ marginRight: '10px' }} onChange={e => handleDogNameChange(e)} />
        </td>
        <td>
          <input type="text" value={ownerName} style={{ marginRight: '10px' }} onChange={e => handleOwnerNameChange(e)} />
        </td>
        <td>
          <input type="text" value={breed} onChange={e => handleBreedChange(e)} />
        </td>
        <td></td>
        <td></td>
        {loading && <td><h3 className='loading-placeholder'>Please wait, the data is loading...</h3></td>}
        {errorRes && <td><h3 className='error-placeholder'>{errorRes}</h3></td>}
      </tr>
    </tbody>
  )
}

export default TableForm