import axios from "axios";

axios.interceptors.response.use(
    (response) => response,
    async (error) => {
        console.log("response error", error)
      if (error.response && error.response.status === 401) {
        console.log("Unauthorized...")  
        // Access token has expired, logout user
        localStorage.removeItem('cbpc_user')
        localStorage.removeItem('token')
        
        const logout = new Event("logout")
        window.dispatchEvent(logout)
      }
      return Promise.reject(error);
    }
  );

export default axios